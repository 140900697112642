import React from "react";
import "../style/CSS/About.css";
import about from "../assets/about.png"
// import check from "../assets/check.png"
// import values from "../assets/values.png"
import valuesImg from "../assets/values-img.jpg"
import mission from "../assets/our-mission.png"

const About = () => {
  return (
    <main className="about">
      <section className="about-section">
        <h1>
          About Us <img src={about} className="about-icon" alt="icon" />
        </h1>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;GCS is a group of investors and experts from
          different fields. We are offering new technologies <br /> and services
          for the market to achieve the goal of producing new green products in
          the way of reaching <br />
          zero environmental damages and zero carbon footprint.
        </p>
      </section>

      <div className="about-flex">
        <section className="mission-section">
          {/* <h2>
            Our Mission <img src={check} className="check-icon" alt="icon" />
          </h2> */}
          <img src={mission} className="mission-badge" alt="icon" />
          <p>Sustainability is the main goal in GCS.</p>
        </section>

        <section className="values-section">
          {/* <h2>
            Our Values <img src={values} className="about-icon" alt="icon" />
          </h2> */}
          <img src={valuesImg} className="values-badge" alt="icon" />
          <ul>
            <li>Sustainability</li>
            <li>Save the environment</li>
            <li>Maintain the environment</li>
          </ul>
        </section>
      </div>
      <br></br>
      <br />
    </main>
  );
};

export default About;
