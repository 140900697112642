import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "../style/CSS/Navbar.css";
import logo from "../assets/logo.png"

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState({ products: false, services: false });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleDropdown = (menu) => {
    if (window.innerWidth <= 1150) { // Only allow toggle on mobile
      setShowDropdown((prev) => ({ ...prev, [menu]: !prev[menu] }));
    }
  };

  const closeDropdownAndMenu = () => {
    setShowDropdown({ products: false, services: false });
    if (window.innerWidth <= 1150) { // Close menu on mobile
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1150) {
        setShowMenu(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="header">
      <nav className="nav container">
        <NavLink to="/" className="nav__logo">
          <img src={logo} alt="Logo" />
        </NavLink>

        <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink to="/" className="nav__link" onClick={closeDropdownAndMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav__item">
              <div className="nav__link dropdown" onClick={() => toggleDropdown('products')}>
                Products
                <ul className={`dropdown-menu ${showDropdown.products ? 'show-dropdown' : ''}`}>
                  <li>
                    <NavLink to="/soon" className="nav__link" onClick={closeDropdownAndMenu}>
                      Green Products - soon!
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="nav__link" onClick={closeDropdownAndMenu}>
                      Sustainable Resources Products - soon!
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav__item">
              <div className="nav__link dropdown" onClick={() => toggleDropdown('services')}>
                Services
                <ul className={`dropdown-menu ${showDropdown.services ? 'show-dropdown' : ''}`}>
                  <li>
                    <NavLink to="/soon" className="nav__link" onClick={closeDropdownAndMenu}>
                      Agricultural Solutions - soon!
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="nav__link" onClick={closeDropdownAndMenu}>
                      Sustainability - soon!
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/soon" className="nav__link" onClick={closeDropdownAndMenu}>
                      Water plant solutions - soon!
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav__item">
              <NavLink to="/about-us" className="nav__link" onClick={closeDropdownAndMenu}>
                About Us
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/contact" className="nav__link" onClick={closeDropdownAndMenu}>
                Contact
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/location" className="nav__link" onClick={closeDropdownAndMenu}>
                Location
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/soon" className="nav__link nav__cta">
                Get Started
              </NavLink>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
