import React from 'react';
import '../style/CSS/Home.css';
import slide1 from '../assets/slide1.jpg'
import slide2 from '../assets/slide2.png'
import slide3 from '../assets/slide3.jpg'
import soon from '../assets/soon.png'

const Home = () => {
  return (
    <main className="home">
      <section className="slider">
        <div className="slider__container">
          <figure>
            <img src={slide1} alt="slide1" className='slide-desktop' />
          </figure>
          <figure>
            <img src={slide2} alt="slide2" className='slide-desktop'/>
          </figure>
          <figure>
            <img src={slide3} alt="slide3" className='slide-desktop'/>
          </figure>
        </div>
        <div className="slider__content">
          <h1>Welcome to Global Consulting and Services</h1>
          <p>Your partner in sustainable solutions</p>
        </div>
      </section>
      {/* <section className="hero">
        <div className="hero-inner">
          <h1>Welcome to Global Consulting and Services</h1> <br />
          <p>Your partner in sustainable solutions.</p>
        </div>
      </section> */}

      <section className="products">
        <h2>Our Products <br /> <span className='soon-span'> <img src={soon} alt='soon' width={15}/> Coming soon!</span></h2>
        <div className="product-list">
          <div className="product-item">
            <h3>Green Products </h3>
            <p>
              Products made from nature and sustainable materials. Embrace
              eco-friendly living with our range of green products.
            </p>
          </div>
          <div className="product-item">
            <h3>Sustainable Resources </h3>
            <p>
              Products based on sustainable resources. We offer a variety of
              items that are both environmentally friendly and sustainable.
            </p>
          </div>
        </div>
      </section>

      <section className="services">
        <h2>Our Services</h2>
        <div className="service-list">
          <div className="service-item">
            <h3>Agricultural Solutions </h3>
            <p>
              Innovative solutions to enhance agricultural productivity and
              sustainability.
            </p>
          </div>
          <div className="service-item">
            <h3>Sustainability </h3>
            <p>
              We provide comprehensive strategies to help businesses achieve
              their sustainability goals.
            </p>
          </div>
          <div className="service-item">
            <h3>Water Plant Solutions </h3>
            <p>
              Advanced solutions for water treatment and management to ensure
              clean and safe water supply.
            </p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
