import React from 'react';
import '../style/CSS/Location.css';
import location from "../assets/location.png"

const Location = () => {
  return (
    <main className="location">
      <div className='location-flex'>
        <section className="location-info">
          <h1> <img src={location} className='location-icon' alt='icon'/> Our Location</h1>
          <p>Welcome to Global Consulting and Services. We are located in the heart of Dubai, UAE.</p>
          <p>Our office is situated in Meydan, a prestigious and well-connected area of the city.</p>
          <p>Feel free to visit us for any inquiries or consultations. We look forward to welcoming you!</p>
        </section>
        <section className="location-map">
          <h2>Find Us Here</h2>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.590421558384!2d55.28214971501143!3d25.17838148389565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f691b93f59e4d%3A0xb402a7e1247d8a8!2sMeydan%20One%20Mall!5e0!3m2!1sen!2sae!4v1628233741943!5m2!1sen!2sae"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              title="GCS Location"
            ></iframe>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Location;
