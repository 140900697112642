import React from "react";
import "../style/CSS/Footer.css";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__content">
        <p>© 2024 Global Consulting and Services. All rights reserved.</p>
        <nav className="footer__nav">
          <NavLink to="/soon" className="anchor">Privacy Policy</NavLink>
          <NavLink to="/soon" className="anchor">Terms of Service</NavLink>
          <NavLink to="/contact" className="anchor">Contact</NavLink>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
